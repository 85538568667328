body {
  background-color: blue;
}

.check-email-background {
  background-image: url("/images/control-panel.png");
  background-size: 100vw 100vh;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(5px);
  width: 100%;
  height: 100%;
}

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: none;
  margin: 0;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
